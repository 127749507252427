<template>
  <section class="sub-box">
    <navi-bar>
      <img src="@/assets_mobile/img/headin_off.png"/>
      <span>{{$t('front.common.point')}}<em>point</em></span>
    </navi-bar>

    <div class="moneytab">
      <a :class="{'on' : type == 'apply'}" @click="type = 'apply'"><span>{{$t('front.mypage.pointChange')}}</span></a>
      <a :class="{'on' : type == 'chargeList'}" @click="type = 'chargeList'"><span>{{$t('front.mypage.pointSave')}}</span></a>
      <a :class="{'on' : type == 'changeList'}" @click="type = 'changeList'"><span>{{$t('front.mypage.pointChangeList')}}</span></a>
    </div>

    <mobile-point-use v-if="type == 'apply'" />
    <mobile-point-list v-if="type == 'chargeList'" />
    <mobile-point-use-list v-if="type == 'changeList'" />

  </section>
</template>

<script>
import NaviBar from '@/components/common/mobile/NaviBar'
import MobilePointUse from '@/views_mobile/member/point/change'
import MobilePointList from '@/views_mobile/member/point/list'
import MobilePointUseList from '@/views_mobile/member/point/uselist'

export default {
  name: 'point.vue',
  components: {
    MobilePointUseList,
    MobilePointList,
    MobilePointUse,
    NaviBar
  },
  data () {
    return {
      type: 'apply'
    }
  }
}
</script>

<style scoped src="@/styles_mobile/betlist.css"></style>
<style scoped src="@/styles_mobile/money.css"></style>
<style scoped src="@/styles_mobile/subcommon.css"></style>
<style scoped src="@/styles_mobile/common.css"></style>
